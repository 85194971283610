import axios from 'axios'
import {Toast, toast} from 'vant'
import utils from './utils'
import store from '../../store/index'
import router from '../../router/index'

//PRD
let key = '1b3e934b59a060aa5d36f53cdbc6b410'
let secret = '03d44d93ec0978ed0465937353adf5fc'
//UAT
// let key = '9rqb2xg2u14mjd243qtyprcz7naqauti'
//  let secret = 'dp68jopn1bfomgn1u3iufh4tbd40xnzw'

let instance = axios.create({
  // https://blljuat.cwebset.cn/
  // https://bllj.cwebset.cn/
  // http://bllj.com/
  baseURL: 'https://bllj.cwebset.cn/',
  timeout: 60000*2
})

let timer = null

instance.interceptors.request.use(function(request) {
  // console.log('请求拦截', request)
  if(request.method === 'get') {
    if(!request.params) request.params = {}
    let params = request.params
    params.api_key = key//添加签名
    params.timestamp = `${parseInt((new Date().getTime()) / 1000)}`
    let sign = utils.getSign(params, key, secret)
    params.api_sign = sign
	
  } else {
    if(!request.data) request.data = {}
    let data = request.data
    data.api_key = key//添加签名
    data.timestamp = `${parseInt((new Date().getTime()) / 1000)}`
    let sign = utils.getSign(data, key, secret)
    data.api_sign = sign
  }
  request.headers.token = store.state.token;
  
  /*******************请求超过800ms未收到回应，需要显示loading弹窗*********************/
  if(timer) {
    //之前有请求且还没收到服务器响应，取消之前的定时，重新定时
    clearTimeout(timer)
    timer = null
  }
  timer = setTimeout(() => {
    Toast.loading({
      message: '努力加载中...',
      forbidClick: true,
      // duration: 0
    })
    timer = null
  }, 800)
  return request
})

instance.interceptors.response.use(function(response) {
  // console.log('响应拦截', response)
  Toast.clear()
  clearTimeout(timer)
  timer = null
  if(response.data.code === 200) {
    return response.data
  } else if(response.data.code === 202 && response.data.error_code === '100001') {
    router.push({
		path:'Login',
		query: {auth: "0"},
		})
  } else if(response.data.code === 202 && response.data.error_code === '201005') {
    store.commit('loginOutMut')
  } else {
    if(response.data.msg !== '没有数据') {
      toast(response.data.msg)
    }
    return false
  }
  
}, function() {
  Toast.clear()
})

export default instance