<template>
  <div class="order-detail-page" v-if="orderDetailInfo.id">
    <div class="receiver-box">
      <div class="row">
        <span class="left">收货人：</span>
        <span class="right">{{orderDetailInfo.consignee_name}} <span class="ml10">{{orderDetailInfo.consignee_mobile}}</span></span>
      </div>
      <div class="row">
        <span class="left">收货地址：</span>
        <span class="right">{{orderDetailInfo.address}}</span>
      </div>
    </div>

    <!-- 商品列表，需要根据是否发货与发货后是否分不同的物流来区分视图的显示 -->

    <!-- 未发货 -->
    <div v-if="orderDetailInfo.not_send_list.length !== 0" class="order-card-list">
      <div class="order-card-list__item" v-for="(item, index) in orderDetailInfo.not_send_list" :key="index">
        <gift-card :data="item"></gift-card>
      </div>
    </div>

    <!-- 已发货或已收货 -->
    <div class="order-card-list" v-for="(item, index) in Object.keys(orderDetailInfo.is_send_list)" :key="index">
      <div class="order-card-list__item" v-for="(giftItem, giftIndex) in orderDetailInfo.is_send_list[item]" :key="giftIndex">
        <gift-card :data="giftItem"></gift-card>
      </div>
      <div class="order-card-list__footer">
        <div >
          <input type="text" :id="`input${index}`" style="opacity: 0; position: absolute; left: -100%" :value="item">
          <span>{{orderDetailInfo.is_send_list[item][0].logistics_name}}：</span>	
          <span class="col-red" v-if="orderDetailInfo.is_send_list[item][0].category_id==22&&(orderDetailInfo.is_send_list[item][0].logistics_name.includes('短信'))">已发送</span>
		  <span class="col-red" v-else-if="orderDetailInfo.is_send_list[item][0].category_id==22&&orderDetailInfo.is_send_list[item][0].logistics_name=='线下领取'">已领取</span>
		<span class="col-red" @click="copy(index,'input')" v-else>{{item}}</span>
		<span v-if="orderDetailInfo.is_send_list[item][0].category_id==22&&orderDetailInfo.id>28198&&(orderDetailInfo.is_send_list[item][0].logistics_name.includes('短信'))" @click="cardListQuery(item)" style="color: #EC2C0F;">（查看卡密）</span>
		<span v-if="orderDetailInfo.is_send_list[item][0].category_id!=22" @click="expressQuery(item)">（物流轨迹）</span>
        </div>
      </div>
    </div>

    <div class="statistics">
      <div class="cell">
        <span>订单号</span>
        <span>{{orderDetailInfo.order_number}}</span>
      </div>
      <div class="cell">
        <span>合计礼品</span>
        <span>{{orderDetailInfo.gitf_num}}件</span>
      </div>
      <div class="cell">
        <span>合计额度</span>
        <span class="col-red">{{$utils.formatNum(orderDetailInfo.total_quota)}}</span>
      </div>
      <div class="cell">
        <span>下单时间</span>
        <span>{{orderDetailInfo.create_time}}</span>
      </div>
    </div>
	

    <van-dialog v-model="show" title="物流轨迹" :width="iframeWidth">
      <van-steps 
        v-if="orderDetailInfo.is_send_list[expressCode]"
        direction="vertical" 
        :active="0" 
        style="max-height: 65vh; overflow: auto"
        active-color="#ec2c0f"
      >
        <van-step v-for="(item, index) in orderDetailInfo.is_send_list[expressCode][0]['wuliu_guiji']" :key="index">
        <h3 class="fz26" style="text-align: left">{{item.context}}</h3>
        <p class="fz22" style="text-align: left">{{item.time}}</p>
        </van-step>
      </van-steps>
    </van-dialog>
	
	<van-dialog v-if="show_card" v-model="show_card" :title="orderDetailInfo.is_send_list[cardList][0]['gitf_name']" :width="iframeWidth">	
	<van-cell v-if="orderDetailInfo.is_send_list[cardList][0]['card_list'].length !== 0" >
		<div class="giftNumber">数量：{{orderDetailInfo.is_send_list[cardList][0]['gitf_number']}} <span class="copyCard">（点击卡号、卡密即可复制）</span></div>
	</van-cell>
	<van-cell v-else  title="卡密生成中.." style="text-align: center;"/>	
	<van-cell class="cardList van-clearfix" v-if="orderDetailInfo.is_send_list[cardList][0]['card_list'].length !== 0">
		<span class="cardnum">卡号</span>
		<span class="cardpass">卡密</span>
		<span class="validdate">有效期</span>
	</van-cell>
	<van-list v-if="orderDetailInfo.is_send_list[cardList][0]['card_list'].length !== 0"
	style="max-height: 65vh; overflow: auto"
	>
	<van-cell v-for="(item, index) in orderDetailInfo.is_send_list[cardList][0]['card_list']" :key="index" 
	class="cardList_item">
		<input type="text" :id="`cardnum${index}`" style="opacity: 0; position: absolute; left: -100%" :value="item.cardnum">
		<input type="text" :id="`cardpass${index}`" style="opacity: 0; position: absolute; left: -100%" :value="item.cardpass">
		<span class="cardnum copy" v-if="item.copy_status==1" :id="`cardnumspan${index}`" @click="copy(index,'cardnum',item.id,item.copy_status)">{{item.cardnum}}</span>
		<span class="cardnum" v-else :id="`cardnumspan${index}`" @click="copy(index,'cardnum',item.id,item.copy_status)">{{item.cardnum}}</span>
		<span class="cardpass copy" v-if="item.copy_status==1" :id="`cardpassspan${index}`" @click="copy(index,'cardpass')">{{item.cardpass}}</span>
		<span class="cardpass" v-else :id="`cardpassspan${index}`" @click="copy(index,'cardpass')">{{item.cardpass}}</span>
		<span class="validdate">{{item.validdate}}</span>
	</van-cell>
	</van-list>	
	</van-dialog>

	<cantact-us />
    
  </div>
</template>

<script>
import GiftCard from '../components/GiftCard.vue'
import CantactUs from '../components/CantactUs.vue'
export default {
  components: {
    GiftCard,
    CantactUs
  },
  data() {
    return {
	show: false,	
	show_card: false,
	iframeWidth: 0,
	iframeHeight: 0,
	expressCode: '',
	cardList: '',
		orderDetailInfo: {}
    }
  },
  methods: {
    expressQuery(code) {
      this.expressCode = code
      this.show = true
    },
	cardListQuery(code) {
	this.cardList = code
	this.show_card = true
	},
    async getOrderDetail() {
      let res = await this.axios.post('client/v1.Member_Order/orderInfo', {order_id: this.$route.query.id})
      if(!res) return
      this.orderDetailInfo = res.data
    },
	async setCopyStatus(id) {	
	  await this.axios.post('client/v1.Member_Order/cardCopyStatus', {id: id})
	},
    copy(index,type,id=0,copy_status) {
      let inputElem = document.getElementById(type+`${index}`)
      inputElem.select()
      document.execCommand("Copy")
	  
	 let typeNamt=''
	 switch(type){
	 		  case 'cardnum':
	 		   typeNamt='卡号'
	 		  break;
	 		  case 'cardpass':
	 		   typeNamt='卡密'
	 		  break;
	 		  default:
	 		  typeNamt='物流单号'
	 		  break;
	 }
      this.$toast(typeNamt+'已复制')
	  
	  if(type=='cardnum'){
		  //复制卡号 卡密 未复制状态 0才送更新
		  let cardnumspanElem = document.getElementById('cardnumspan'+index)
		  let isCopy=cardnumspanElem.getAttribute('class').indexOf('copy'); 
		 
		   if((copy_status==0||copy_status==null)&&isCopy==-1){
			  this.setCopyStatus(id)
			  cardnumspanElem.setAttribute('class','cardnum copy');
		  }
	  }
	  if(type=='cardpass'){
	  		  //复制卡密修改样式
	  		  let cardpassspanElem = document.getElementById('cardpassspan'+index)
			  let isCopy_pass=cardpassspanElem.getAttribute('class').indexOf('copy')
	  		   if(copy_status==null&&isCopy_pass==-1){
	  			  cardpassspanElem.setAttribute('class','cardpass copy');
	  		  }
	  }
    }
  },
  created() {
    this.iframeWidth = window.innerWidth - 40
    this.iframeHeight = window.innerHeight - 150
    this.getOrderDetail()
  }
}
</script>

<style lang="scss">
.order-detail-page {
  iframe {
    border: 0;
  }
  .van-dialog {
    top: 50%;
  }
  .van-dialog .giftNumber{
	text-align: left;
	font-size: 28px;
  }
  .van-dialog .giftNumber .copyCard{
	color: #ee0a24;
  }
  // .van-dialog .cardList_item{border-bottom: 0.5px #666 dashed;margin:15px auto;}
  .van-dialog .cardList span,.van-dialog .cardList_item span{float: left;}
  .van-dialog .cardList_item span{color: #023466;}
  .van-dialog .cardList_item span.copy{color: #771caa;}
  .van-dialog span.cardnum{width:35%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
  .van-dialog span.cardpass{width:35%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
  .van-dialog span.validdate{color:#323233;}
  
  .order-card-list {
    margin: 20px;
    border-radius: 15px;
    overflow: hidden;
    &__title {
        display: flex;
        align-items: center;
        height: 80px;
        background-color: #fff;
        border-bottom: 1px solid #d9d9d9;
      }
    &__item {
      border-bottom: 1px solid #d9d9d9;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px;
      height: 80px;
      background-color: #fff;
      font-size: 24px;
      i {
        font-style: inherit;
      }
    }
  }
  .statistics {
    padding: 20px;
    margin: 20px;
    border-radius: 15px;
    background-color: #fff;
    .cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      font-size: 24px;
    }
  }
  .receiver-box {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    text-align: left;
    border-radius: 15px;
  }
  .row {
    display: flex;
    align-items: flex-start;
    min-height: 55px;
    .left {
      flex-shrink: 0;
      width: 5em;
      text-align-last: justify;
    }
  }
}
</style>